@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src: local('Roboto'),
  url('../default/Roboto.woff2') format('woff2'),
  url('../default/Roboto.woff') format('woff'),
  url('../default/Roboto.ttf') format('truetype'),
  url('../default/Roboto.eot'),
  url('../default/Roboto.eot') format('embedded-opentype'),
  url('../default/Roboto.svg') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  src: local('Roboto'),
  url('../default/RobotoBold.woff2') format('woff2'),
  url('../default/RobotoBold.woff') format('woff'),
  url('../default/RobotoBold.ttf') format('truetype'),
  url('../default/RobotoBold.eot'),
  url('../default/RobotoBold.eot') format('embedded-opentype'),
  url('../default/RobotoBold.svg') format('svg');
}