
html {
  -webkit-text-size-adjust: none;
}
body {
  width: 100%;
  height: 100%;
  font-family: $font-f;
  font-size: 14px;
  line-height: 16px;
  background: c(skin-15);
  color: c(text-1);
  overflow-y: scroll;
  overflow-x: hidden;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
input[type='password'],
input[type='text'] {
  -moz-user-select: all;
  -khtml-user-select: all;
  -ms-user-select: all;
  -webkit-user-select: initial;
  user-select: all;
}

.popup-opened-m {
  overflow: hidden;
  bottom: 0;
  left: 0;
}

#root {
  float: left;
  width: 100%;
  height: 100%;
}

.main-wrapper {
  float: left;
  width: 100%;
  height: 100%;
  &.no-scroll {
    overflow: hidden;
  }
}

.wrapper-m {
  float: left;
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  padding: 48px 0 0;
  > .footer-m {
    padding: 10px 10px 50px;
  }
}
.integration-view {
  padding: 48px 0;
}

.full-container-m {
  display: table-row;
  height: 100%;
}

.import-view-container {
  float: left;
  width: 100%;
}

.please-login {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  padding: 50px 10px;
  text-align: center;
  color: c(text-6);
}

.profile-form-contain-m .form-p-i-m {
  > .imp-file-box {
    .select-contain-m {
      margin-bottom: 15px;
      width: 100%;
      > select {
        color: #818791;
        width: 100%;
      }
    }
  }
}

.hide {
  display: none;
}

//Content*
.content-m {
  float: left;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &.content-top {
    padding-top: 40px;
  }
}

.deposit-view-wrapper {
  float: left;
  width: 100%;
}
[dir='rtl'] .sport-league-n-date {
  padding: 8px 15px 10px 0;
}
//tablet view
@media screen and (min-width: 500px) {
  .game-date-view-m > ul {
    width: calc(100% - 58px) !important;
  }
  [dir='rtl'] .game-date-view-m > ul {
    float: right;
  }
  .content-m {
    .game-information-m > ul {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 980px) {
  .sports-navigation-scroll-buttons,
  .left-nav-container-m .menu-right-arr,
  .left-nav-container-m .menu-left-arr {
    display: none;
  }
}
//tablet view END

// add styles for arab and hibrid languages
html[dir='rtl'] {
  //start text move to right
  .team-names-mini-w,
  .name-sub-u-m-title,
  .single-universal-menu-contain,
  .event-text-v-b,
  .name-market-contain-cell-m > i,
  .single-team-info-m > p,
  .league-name-view-m > h3,
  .game-date-view-m > ul > li:first-child > p,
  .info-menu-contain-m > ul > li > a {
    text-align: right;
  }
  .game-info-mini-m > p,
  .game-date-view-m > ul > li:first-child > p.live-game-competition-m,
  .competition-list-view-m > ul > li > a > p,
  .country-games-info-m > p > i,
  .name-sub-u-m-title p span,
  .details-form-item-m,
  .single-form-item > input[type='text'],
  .single-form-item > input[type='password'],
  .single-form-item > input[type='email'],
  .single-form-item > input[type='number'],
  .single-form-item > input[type='tel'],
  .single-form-item > .react-tel-input > input[type='tel'],
  .title-view-match-day > h2,
  .deposit-b-total-b > ul > li > p,
  .bonus-view-m > ul > li,
  .name-d-method > i,
  .promo-text-box,
  .infoText,
  .single-team-info-m {
    text-align: right;
  }
  .single-form-item {
    height: inherit;
  }
  //end  text move to right

  //start rotate styles
  .date-game-icons-m {
    right: 0;
    left: inherit;
  }
  .icon-arrow-single-game {
    left: 0;
    right: inherit;
  }
  .mini-contain-name-separator {
    direction: rtl;
  }
  .live-game-info-m {
    padding: 0 60px 0 30px;
  }
  .collapse-arrow-view {
    left: 0;
    right: inherit;
  }
  .dashboard-column-title:before {
    right: 10px;
  }
  .dashboard-column-title,
  .single-sport-title-dashboard-m.active {
    direction: rtl;
  }
  .closed-open-arrow-m {
    left: 0;
    right: inherit;
  }
  .dashboard-sport-icon-m {
    right: 0;
    padding: 0 10px 0 0;
  }
  .dashboard-sport-icon-m {
    box-shadow: -3px 0 0 c(skin-6) inset;
  }
  .left-t .single-team-info-m h4,
  .left-t .single-team-info-m > p {
    text-align: left;
  }
  .left-t > .single-team-info-m {
    padding: 0 60px 0 10px;
  }
  .teams-name-info-m > ul > li {
    float: left;
    direction: rtl;
  }
  .single-coefficient-m {
    direction: rtl;
  }
  .single-coefficient-m > p > b {
    padding: 0 10px 0 0;
  }
  .games-list-view-dashboard {
    direction: rtl;
  }
  .single-sport-title-dashboard-m {
    padding: 0 34px 0 30px;
  }
  .game-date-view-m > ul > li:first-child > p.live-game-competition-m {
    padding-left: 10px;
    padding-right: 40px;
  }
  .live-game-competition-m > .flag-view-m {
    left: auto;
    right: 10px;
  }
  .arrow-game-view-m {
    left: 0;
    right: inherit;
  }
  .game-title-info-row-m > ul > li.time-column-m {
    right: 0;
    left: inherit;
  }
  .game-title-info-row-m > ul > li.market-count-view-m {
    left: 0;
    right: inherit;
  }
  .names-mini-row-m > ul > li {
    float: right;
  }
  .select-sport-title-m.Soccer {
    direction: rtl;
  }
  .select-sport-title-m > h2 > b {
    text-align: right;
    padding: 0 10px 0 0;
  }
  .competition-list-view-m > ul > li > a > p {
    padding: 0 8px 0 38px;
  }
  .competition-list-view-m > ul > li > a > p > i {
    left: 8px;
    right: inherit;
  }
  .bread-crumbs-view-m > p {
    text-align: right;
    padding: 0 30px 0 12px;
  }
  .bread-crumbs-view-m > a {
    right: 0;
    left: inherit;
  }
  .game-date-view-m > ul {
    direction: rtl;
  }
  .single-game-list-item-m {
    white-space: nowrap;
    direction: rtl;
  }
  .asian-filter-picker ul li:first-child > .title-row-u-m {
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06) inset, 1px 0 0 rgba(255, 255, 255, 0.09) inset;
  }
  .asian-game-c > .game-information-m > ul {
    float: left;
  }
  .markets-count-view-m {
    float: left;
  }
  .time-view-game-m {
    float: right;
  }
  .time-markets-count-m > .icons-game-info-m {
    float: right;
  }
  .markets-count-view-m {
    padding: 0 0 0 10px;
  }
  .markets-count-view-m:before {
    left: -5px;
    right: inherit;
    content: '\e66f';
  }
  .swiping_style {
    right: -56px;
  }
  .asian-view .asian-view-wrapper {
    float: right;
  }
  .asian-game-c > .game-information-m > ul > li.fav-game-icon-m {
    box-shadow: none;
  }
  .asian-game-c > .game-information-m:first-child {
    box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset, 1px 0 0 c(skin-14) inset;
  }
  .sport-nav-container-m > a.active {
    position: relative;
  }
  //end rotate styles

  //start of menu stypes
  .mini-sport-nav-box > .single-sport-title-dashboard-m {
    direction: rtl;
  }
  .mini-sport-nav-box > .single-sport-title-dashboard-m > a {
    padding: 0 0 0 34px;
  }
  .title-row-u-m {
    direction: rtl;
  }
  .icon-view-u-m {
    right: 0;
    left: inherit;
  }
  .arrow-u-m {
    left: 0;
    right: inherit;
  }
  .language-container-m > ul {
    direction: rtl;
  }
  .nav-bar-contain-m,
  .sport-nav-container-m {
    direction: rtl;
  }
  .balance-h-row-b > h5.green,
  .balance-h-row-b > h6,
  .balance-h-row-b > h5 {
    float: right;
  }
  .balance-h-row-b > span,
  .balance-h-row-b > p {
    float: left;
  }
  .arrow-view-nav {
    left: 0;
    right: inherit;
  }
  .arrow-view-nav:before {
    content: '\e66f';
  }
  .menu-provider-view > p {
    padding: 0 10px 0 30px;
  }
  //end of menu styles

  //start of betslip part
  .all-bets-settings-row-m > ul > li {
    float: right;
  }
  .betslip-events-container {
    direction: rtl;
  }
  .event-remove-b-m {
    left: 0;
    right: inherit;
  }
  .icon-status-view-m {
    margin: 0 0 3px 8px;
  }
  .team-name-view-b-m {
    padding: 0 0 10px 25px;
  }
  .market-full-info-m > ul > li > span {
    float: left;
  }
  .kind-of-bet {
    right: 0;
    left: inherit;
  }
  .info-i-switcher-b {
    left: 0;
    right: inherit;
  }
  .bet-kind-container {
    padding: 2px 46px 0 91px;
  }
  .sub-error-info:before {
    right: 4px;
    left: inherit;
  }
  .ew-total-odd > ul > li > p {
    float: left;
  }
  .possible-win-container > ul > li > span {
    float: left;
  }
  .possible-win-container > ul > li > p {
    float: right;
  }
  .betslip-settings.open + .b-settings-view-b {
    direction: rtl;
  }
  .radio-form-item > label:before {
    right: 5px;
    left: inherit;
  }
  .quick-bet-settings-view .radio-form-item input:checked + span:before {
    right: 10px;
  }

  .radio-form-item > label > input {
    left: auto;
  }
  .radio-form-item {
    text-align: right;
  }
  .radio-form-item > label > input:checked + span:before {
    right: 5px;
    left: inherit;
  }
  .radio-form-item > label {
    padding: 5px 25px 5px 0;
  }
  .max-bet-b-m {
    border-right: none;
    border-left: 1px solid c(text-6);
  }
  //end of betslip part

  //start of login user part
  .user-name-contain-m {
    direction: rtl;
  }
  .user-icon-m {
    right: 0;
    left: inherit;
  }
  .user-name-contain-m {
    padding: 0 44px 0 10px;
  }
  .deposit-b-total-b,
  .bonus-view-m {
    direction: rtl;
  }
  .title-row-u-m > p > span {
    padding: 0 0 0 28px;
  }
  .single-method-deposit > ul > li:first-child {
    float: right;
  }
  //.single-method-deposit > ul > li:first-child{
  //   float: left;
  // }
  .deposit-method-b {
    float: left;
  }
  .bonus-title-view > h3 {
    text-align: right;
  }
  .bonus-info-contain {
    direction: rtl;
  }
  .bonus-info-contain > ul > li > p {
    padding: 0 10px 5px 0;
  }
  .more-bonus-info {
    padding: 10px 10px 0 0;
  }
  .bonus-more-view-wrapper {
    direction: rtl;
  }
  .bonus-claim-button-w > span {
    text-align: right;
  }
  .profile-info-contain-m {
    direction: rtl;
  }
  .inputfile + label > span {
    box-shadow: -1px 0 0 c(text-6) inset, 0 1px 0 c(text-6) inset,
    0 -1px 0 c(text-6) inset;
  }
  .self-exclusion-container-m > p {
    text-align: right;
  }
  .change-password-container-m,
  .page-menu-contain {
    direction: rtl;
  }
  .promo-text-box table,
  .page-container {
    direction: rtl;
  }
  .page-section:after {
    left: 0;
    right: inherit;
  }
  .page-section {
    padding: 0 12px 0 38px;
  }
  .deposits-wrapper {
    direction: rtl;
  }
  .checkbox-wrapper-m > input[type='checkbox'] {
    left: inherit;
  }
  .checkbox-wrapper-m {
    height: 45px;
  }
  .checkbox-wrapper-m > span {
    white-space: inherit;
    text-overflow: inherit;
    overflow: inherit;
  }

  //end of login user part

  //start casino part
  .provider-menu {
    direction: rtl;
  }
  .casino-game-title > b {
    direction: rtl;
    text-align: right;
  }
  .single-game-type-list {
    direction: rtl;
  }
  .arrow-view-open-box {
    left: 0;
    right: inherit;
  }
  .title-game-type-box {
    padding: 0 10px 0 34px;
  }
  .title-single-game {
    direction: rtl;
    text-align: right;
  }
  .title-row-u-m > p > span > i {
    right: auto;
    left: 0;
  }

  .single-form-item.reg_password > div a {
    right: inherit !important;
    left: 6px;
  }
  .single-form-item.reg_g_recaptcha_response {
    height: 90px;
  }
  .single-form-item .img-wrapper-m {
    float: right;
    margin: 15px 0px 0 10px;
  }
  //bug
  .header-separator-m,
  .statistic-view-m,
  .timeline-container,
  .game-animation-container {
    direction: ltr;
  }
  .live-game-stats-table > .game-list-statistics {
    overflow: hidden;
  }
  .game-score-view > ul > li:first-child {
    text-align: left;
  }

  //bug

  //end of casino part

  //start of arrow change part
  .icon-arrow-single-game:before,
  .time-markets-count-m .markets-count-view-m:before,
  .name-sub-u-m-title > .arrow-u-m:before,
  .closed-open-arrow-m:before {
    content: '\e66f';
  }
  .back-arrow-crumbs:before,
  .markets-count-view-m:before {
    content: '\e672';
  }
  .stake-form-b-m .single-form-item {
    height: 38px;
  }
  //end of arrow change part

  //start search results
  /*.closed-search-icon-m {
    left: 0;
    right: inherit;
  }*/
  .search-result-title > h3 {
    text-align: right;
  }
  .single-game-item-c > ul > li:first-child {
    float: right;
  }
  .results-game-title {
    text-align: right;
  }
  .result-sport-icon {
    right: 5px;
    left: inherit;
  }
  .sport-title-result-m {
    padding: 0 32px 0 12px;
  }
  .teams-name-result-m {
    text-align: right;
  }
  .flag-date-competition-m {
    padding: 0 40px 0 12px;
  }
  .additional-info-m {
    text-align: right;
  }
  //end search results

  //start of game animation part
  .live-score-view > span {
    text-align: center;
  }
  .team-name-live-game {
    text-align: right;
  }
  .game-score-info-l-game > ul > li:first-child > .team-name-live-game {
    text-align: left;
  }
  .statistic-line-view > span:first-child > i {
    left: auto;
    right: -24px;
  }

  .statistic-line-view > span > i {
    left: -24px;
    right: inherit;
  }
  .statistic-view-m {
    direction: rtl;
  }
  //end of game animation part

  //start football marker part
  .single-team-info-m {
    padding: 0 60px 0 10px;
  }
  .teams-name-info-m > ul > li {
    float: right;
  }
  .team-logo-contain-view {
    right: 0;
    left: inherit;
  }
  .teams-name-info-m > ul > li:first-child span {
    left: 0;
    right: auto;
  }
  .teams-name-info-m > ul > li:first-child .single-team-info-m {
    padding: 0 10px 0 60px;
  }
  //end off football marker part
  @media screen and (min-width: 980px) {
    .content-m > .game-view-wrapper,
    .content-m > .import-view-container,
    .deposit-view-wrapper,
    .loyalty-page-wrapper,
    .profile-view-wrapper {
      float: right;
    }
    .asian-view-filters-wrapper-m .import-view-container {
      left: auto;
      right: 260px;
    }
  }
  .quick-bet-settings-view > h3 {
    text-align: left;
  }
  .all-bets-settings-row-m > ul > li.cleared-all-box {
    float: left;
  }
  .asian-view .fav-star-m {
    right: 0;
    box-shadow: 1px 0 0 c(skin-14) inset;
  }
  .asian-view p.select-contain-m.match-a {
    margin: 0 34px 0 0;
  }
  .asian-view .singe-market-view-m .single-market-title-m {
    padding: 0 70px 0 46px;
  }
  .asian-view .singe-market-view-m .markets-closed-open-arrow-m {
    right: 35px;
  }
  .delete-counter-offer:before {
    right: auto;
    left: 5px;
  }
  // New Rtl Styles
  .open-close-arrow-e {
    right: auto;
    left: 0;
    &:before {
      content: '\E66F';
    }
  }
  .go-to-events-m {
    > a {
      padding: 0 10px 0 0;
    }
  }
  .single-sport-title-dashboard-mm {
    &:before {
      left: auto;
      right: 8px;
    }
  }
  .game-type-c {
    > h3,
    > span {
      float: right;
    }
    > b {
      float: left;
      padding: 0 0 0 30px;
      &:after {
        right: inherit;
        left: 5px;
        content: '\E66F';
      }
    }
  }
  .pro-filter-m > .icon-view-u-m {
    right: 0;
    left: auto;
  }
  .pro-filter-m > p {
    padding: 0 40px 0 0;
  }
  .language-container-m .select-contain-m > .flag-view-m {
    left: inherit;
    right: 35px;
  }
  .time-filter-m > ul {
    float: right;
  }
  .cleared-all-box .clear-all-m {
    float: left;
  }
  .game-control.Tennis .Point .score_2 {
    left: 50%;
    margin-left: -40px;
    margin-right: auto;
  }
  .game-control.Tennis .Point .score_1 {
    right: 50%;
    margin-right: -40px;
    margin-left: auto;
  }
  .user-info-show-b > span:last-child {
    text-align: left;
  }
  .add-new-selections-wrapper > p {
    padding-right: 25px;
    &:before {
      left: auto;
      right: 10px;
    }
  }
  .edit-bet-stake-amount > span > i {
    padding: 0 5px 0 0px;
    float: left;
  }
  .edit-bet-win-info span {
    float: right;
    text-align: right;
  }
  .under-add-selection-i > span {
    width: auto;
  }
  .possible-win-block-b > span > i {
    text-align: center;
    width: 100%;
    float: left;
  }
  .add-selections:after {
    left: 0;
    right: inherit;
  }
  .new-selections-info > button {
    position: absolute;
    left: 10px;
    right: auto;
  }
  .new-selections-info > p {
    padding-right: 20px;
  }
  .new-selections-info > p:before {
    right: 5px;
    left: auto;
  }
  .edit-bet-win-info span:nth-child(2) {
    padding-right: 10px;
  }
  .edit-bet-win-info ul > li > p.possible-text-title-m {
    //float: right;
    //text-align: right;
    display: block;
    width: auto;
    padding-right: 0;
    line-height: 18px;
    > span {
      padding-right: 5px;
      float: left;
      > i {
        // line-height: 14px;
      }
    }
  }
  .System .checkbox-wrapper-m > input[type='checkbox']:checked + span:before,
  .System .checkbox-wrapper-m:before {
    right: 18px;
  }
  // add autoceshaut styles
  .cashout-menu-p > button:last-child:before {
    padding: 0 0 0 10px;
  }
  .radio-select-b {
    left: auto;
    right: 0;
  }
  .full-cash-o > span {
    float: right;
    padding: 0 10px 0 0;
  }
  .full-cash-o .price-currency {
    float: left;
  }
  .full-cash-o > input[type='radio'] {
    float: right;
  }
  .partial-cashout-price > p:last-child {
    text-align: left;
  }
  .partial-cashout-price > p {
    text-align: right;
  }
  .log-out-b-m > button {
    padding: 0 38px 0 10px;
    text-align: right;
  }
  .log-out-b-m > button:before {
    right: 0;
  }
  // add styles for virtual sport nubers
  .singe-market-view-m.virtual .single-coefficient-m.virtual-g .event-text-v-b {
    padding-left: inherit;
    padding-right: 40px;

    &:before {
      left: auto;
      right: 0px;
    }
  }
  // add styles for virtual sports
  .provider-panel-m .l-casino-provider-nav h6 {
    float: right;
  }
  .provider-panel-m .l-casino-provider-nav .provider-selector-p {
    float: left;
    padding-left: inherit;
    padding-right: 20px;
    &:after {
      right: auto;
      left: 0;
    }
  }
  .m-points-ex > div:last-child {
    text-align: left;
  }
  .current-proggres-proc.less > span {
    left: auto;
    right: 0;
  }
}

//end of styles of arab and hibrid languages

// start footer live chat button part
.footerLivechatButton {
  width: 57px;
  height: 57px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 10000;
  text-align: center;
  border-radius: 50%;
  font: 28px/36px icomoon;
  &:before {
    content: '\E904';
    line-height: 57px;
  }
}

.customDomElementClass {
  position: fixed;
  bottom: 40px;
  z-index: 100000;
  right: 15px;
}

// end footer live chat bitton part

//position sticky
@supports (position: sticky) or (position: -webkit-sticky) {
  .sport-region-info-m,
  .game-date-view-m,
  .game-markets-f,
  .dashboard-live-game-list-m .single-sport-title-dashboard-m,
  .dashboard-upcoming-game-list-m .single-sport-title-dashboard-m,
  .title-game-type-box {
    top: 48px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 555;
  }
  .title-row-u-m {
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 555;
  }
  .sport-nav-container-m > a.active {
    left: 0;
    right: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 33;
    box-shadow: 1px 0 0 c(skin-12), -1px 0 0 c(skin-12);
  }
}

@media screen and (min-width: 980px) {
  .sport-region-info-m,
  .game-date-view-m,
  .game-markets-f,
  .dashboard-live-game-list-m .single-sport-title-dashboard-m,
  .dashboard-upcoming-game-list-m .single-sport-title-dashboard-m {
    top: 0;
    position: relative;
  }
}

//position sticky END
body {
  @import 'custom';
}

.slick-slider {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  .slick-list {
    float: left;
    width: 100%;
    transition: 300ms height;
    &[style='height: 0;'],
    &[style='height: 0px;'] {
      height: auto !important;
    }
    .slick-track {
      float: left;
      width: 100%;
      font: 0/0 $font-f;
      white-space: nowrap;
      > div {
        display: inline-block;
        vertical-align: top;
        font: 12px/14px $font-f;
        background: c(skin-8);
      }
    }
  }
  .slick-dots {
    float: left;
    width: 100%;
    height: 40px;
    background: c(skin-8);
    text-align: center;
    font: 0/40px $font-f;
    position: relative;
    z-index: 20;
    li {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      background: c(skin-5);
      margin: 0 2px;
      border-radius: 100%;
      transition: 300ms background, 300ms transform;
      transform: scale(0.8);
      button {
        display: none;
      }
      &.slick-active {
        background: c(skin-3);
        transform: scale(1);
      }
    }
  }
  > button {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: c(text-5);
    z-index: 30;
    border: 0;
    background: 0;
    font: 0/0 $font-f;
    text-align: center;
    &:before {
      content: '\E672';
      font: 20px/40px icomoon;
    }
    &:first-child {
      left: 0;
      right: auto;
      &:before {
        content: '\E66F';
      }
    }
  }
}
.no-more-empty-d {
  float: left;
  width: 100%;
}
.messages > .title-row-u-m > p > span {
  padding: 0 28px 0 0;
}
//add new filters
.asian-filter-picker .title-row-u-m > p > span {
  padding: 0;
}
.asian-filter-picker ul li,
.asian-filter-picker ul {
  width: auto;
}
.asian-filter-picker {
  white-space: nowrap;
}
.asian-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}
// add future competition styles
.icon-view-u-m.icon-stopwatch {
  content: '\e6e2';
}
.asian-view-wrapper.future .asian-filter-picker ul,
.asian-view-wrapper.future .asian-filter-picker ul li {
  width: auto;
}
.asian-view-wrapper.future .title-row-u-m > p > span {
  padding: 0;
}
.asian-view-wrapper.future .asian-filter-picker ul li:last-child {
  border-left:1px solid c(skin-8);
}
.asian-view-wrapper .asian-filter-picker ul {
  width: 100%;
}
.asian-view-wrapper.future .asian-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}
// fix service animation bug in live
#statscore-tracker-container > div > iframe {
  display: block;
}

[dir='rtl'] {
  .big-status-label-p > div {
    float: right;
    padding-right: 10px;
  }
  .data-nav-bar-contain-m > ul > li {
    &:after {
      right: auto;
      left: 0;
      content: '\E66F';
    }
    > a {
      padding: 0 40px 0 30px;
      &:before {
        right: 10px;
        left: auto;
      }
    }
  }
  .slick-list {
    direction: ltr;
  }
}
