html, body, .main-wrapper, #root {
  height: 100%;
  margin: 0;
}
.loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial;
  font-size: 13px;
  background: #0e141a;
  position: fixed;
  top: 0;
  left: 0;
}
.line-loader-splash {
  span {
    display: inline-block;
    height: 25px;
    width: 9px;
    margin: 0 3px;
    background: #fff;
    animation: load 0.9s ease-in-out 0s infinite;
    border-radius: 1px;
    &:nth-child(1) {
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      animation-delay: 0s;
    }
  }
}

@keyframes load {
  50% {
    transform: scaley(2.3);
  }
  100% {
    transform: scaley(1);
  }
}
